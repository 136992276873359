import { useSettings } from '@backpackjs/storefront';
import { Icons } from '../Icons';

import { Link } from '../Link';

export function DesktopRightMenu() {
  const settings = useSettings();

  return (
    <div className="hidden items-center gap-[10px] justify-self-end whitespace-nowrap lg:flex">
      {settings?.header?.ctas?.getStarted?.url ? (
        <Link
          className="font-aeonik text-sm font-medium leading-[1.125rem] text-grey-400 no-underline antialiased transition-colors hover:text-primary-light"
          href={settings.header.ctas.getStarted.url}
          newTab={settings.header.ctas.getStarted?.newTab}
        >
          {settings.header.ctas.getStarted.text}
        </Link>
      ) : null}

      {settings?.header?.ctas?.demo?.url ? (
        <Link
          className="flex items-center justify-center gap-1.5 px-3 font-aeonik text-sm font-medium leading-[1.125rem] text-grey-400 no-underline antialiased transition-colors hover:text-primary-light"
          href={settings.header.ctas.demo.url}
          newTab={settings.header.ctas.demo?.newTab}
        >
          <Icons
            icon="video"
            className="h-[18px] w-[18px] text-primary-green-500"
          />
          {settings.header.ctas.demo.text}
        </Link>
      ) : null}

      {settings?.header?.ctas?.login?.url ? (
        <Link
          className="btn-dark h-8 whitespace-nowrap rounded-[10px] py-0 px-3 text-sm font-medium leading-[1.125rem] text-white"
          href={settings.header.ctas.login.url}
          newTab={settings.header.ctas.login?.newTab}
        >
          {settings.header.ctas.login.text}
        </Link>
      ) : null}
    </div>
  );
}
